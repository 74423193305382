.LoginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    height: 100vh;
}

.arterial-logo {
    max-width: 100%; /* You can adjust this value to your desired width */
    max-height: 30%;
    object-fit: contain; /* Maintain aspect ratio */
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.UserButton {
    background-color: #8a0d0d;
    border: none;
    color: #ffffff;
    text-align: top;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 3px 2px;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 3px;
    transition-duration: 0.2s;
}

.UserButton:hover {
    background-color: #bc4d4d;
    color: rgb(213, 163, 163);
}

.LoginButton{
    background-color: #8a0d0d;
    border: none;
    color: rgb(255, 255, 255);
    text-align: top;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 15px 2px;
    cursor: pointer;
    padding: 10px 50px;
    border-radius: 20px;
    transition-duration: 0.2s;
}

.LoginButton:hover {
    background-color: #ffd3d3;
    color: rgb(81, 7, 7);
}

input[type="text"]::placeholder,
input[type="password"]::placeholder {
    color: #ffffff; /* Change the color to your desired value */
    opacity: 0.4;
}