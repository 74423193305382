.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    border-bottom: 3px solid rgba(49, 49, 49, 0.4);
  }
  
.top-bar-logo {
    max-height: 50px;
    margin: 0 auto;
    /* margin-left: 1%; */
}

.demo-label {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background-color: #990000;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 20px;
}