.patient-list {
    background-color: #000000;
    min-height: 100vh;
    padding: 20px;
    color: #ffffff;
    padding-top: 70px;
}

.patient-list h2 {
    text-align: center;
    color: #d0d0d0;
    margin-bottom: 30px;
    margin-top: 0;
}

.patient-box {
    background-color: #1a1a1a;
    border: 1px solid #8a0d0d;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.patient-box:hover {
    background-color: #2a2a2a;
    box-shadow: 0 0 10px rgba(138, 13, 13, 0.5);
}

.patient-box h3 {
    color: #bc4d4d;
    margin-top: 0;
}

.patient-box p {
    margin: 10px 0;
}

.patient-box a {
    display: inline-block;
    background-color: #8a0d0d;
    color: #ffffff;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 3px;
    transition: background-color 0.2s;
}

.patient-box a:hover {
    background-color: #bc4d4d;
}