.back-button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
    transition-duration: 0.2s;
  }

.back-button:hover {
    color: rgb(213, 163, 163);
}

.arrow-left {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #9898989f;
    border-right: 2px solid #9898989f;
    transform: rotate(-135deg);
  }