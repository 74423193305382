.dropdown-menu {
    position: relative;
    display: inline-block;
    z-index: 10002;
  }

.dropdown-menu.show .dropdown-content {
  display: block;
  position: absolute;
  background-color: #242424;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0);
  z-index: 10002;
  border-radius: 20px;
  right: 0; 
  text-align: center;
}

.dropdown-menu.show .dropdown-content:hover {
    background-color: #666666;
    color: rgb(255, 255, 255);
    opacity: 1;
}

.menu-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.menu-bar {
    width: 20px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    display: block;
}
