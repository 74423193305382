.logout-container {
    display: inline-block;
}

.LogoutButton {
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  border: 2px #8a0d0d7e;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 14px;
  margin: 0px 0px;
  cursor: pointer;
  padding: 10px 20px;
  transition-duration: 0.2s;
  opacity: 0.7;
  width: 100%; /* Add this line to make the button take up the full width of the dropdown */
  box-sizing: border-box; /* Add this line to include the padding in the width calculation */
  font-weight: 600;
}